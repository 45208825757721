// extracted by mini-css-extract-plugin
export var active = "Pagination__active__EK2e1";
export var column = "Pagination__column__pRq2C";
export var disabled = "Pagination__disabled__FbUC6";
export var flex = "Pagination__flex__DwHSX";
export var flexColumn = "Pagination__flexColumn__Z4xK0";
export var gap1 = "Pagination__gap1__aoXRh";
export var gap2 = "Pagination__gap2__EJjiH";
export var gap3 = "Pagination__gap3__HT6F_";
export var gap4 = "Pagination__gap4__AyBux";
export var gap5 = "Pagination__gap5__tImuc";
export var pageItem = "Pagination__pageItem__NsQSw";
export var row = "Pagination__row__lF2Xq";
export var symbol = "Pagination__symbol__KHv6r";