// extracted by mini-css-extract-plugin
export var address = "InfoBoxLocation__address__MROyj";
export var arrow = "InfoBoxLocation__arrow__cCy6g";
export var closeBtn = "InfoBoxLocation__closeBtn__ZJvHN";
export var column = "InfoBoxLocation__column__A1ucv";
export var details = "InfoBoxLocation__details__iqxYG";
export var flex = "InfoBoxLocation__flex__SWvKM";
export var flexColumn = "InfoBoxLocation__flexColumn__n7FMH";
export var gap1 = "InfoBoxLocation__gap1__dr1gD";
export var gap2 = "InfoBoxLocation__gap2__PFhJM";
export var gap3 = "InfoBoxLocation__gap3__FKqRu";
export var gap4 = "InfoBoxLocation__gap4__p1Ca4";
export var gap5 = "InfoBoxLocation__gap5__h2jhh";
export var image = "InfoBoxLocation__image__t23U3";
export var infoWindow = "InfoBoxLocation__infoWindow__fLBj4";
export var label = "InfoBoxLocation__label__PDllq";
export var location = "InfoBoxLocation__location__vueVy";
export var locationCheckbox = "InfoBoxLocation__locationCheckbox__yADiE";
export var locationUnavailable = "InfoBoxLocation__locationUnavailable__F4icg";
export var logo = "InfoBoxLocation__logo__S31dv";
export var name = "InfoBoxLocation__name__DwhSa";
export var paginator = "InfoBoxLocation__paginator__RYCHL";
export var productsInfo = "InfoBoxLocation__productsInfo__U5DO6";
export var provider = "InfoBoxLocation__provider__pDGiC";
export var row = "InfoBoxLocation__row__Q9_YB";
export var selected = "InfoBoxLocation__selected__dMN4e";
export var sponsored = "InfoBoxLocation__sponsored__MLJwe";
export var sponsoredMark = "InfoBoxLocation__sponsoredMark__cMKJr";
export var withPagination = "InfoBoxLocation__withPagination__N4ayT";
export var withSelected = "InfoBoxLocation__withSelected__HEAoY";