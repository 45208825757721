// extracted by mini-css-extract-plugin
export var column = "Link__column__pEN4T";
export var flex = "Link__flex__yPTqa";
export var flexColumn = "Link__flexColumn__IRHVb";
export var gap1 = "Link__gap1__PT0qr";
export var gap2 = "Link__gap2__nKVKV";
export var gap3 = "Link__gap3__u0bKM";
export var gap4 = "Link__gap4__q5jG1";
export var gap5 = "Link__gap5__IyelJ";
export var link = "Link__link__Ivmt3";
export var row = "Link__row__R9iCv";